// Swiper Class
import Swiper from './components/core/core-class';

//IMPORT-COMPONENTS

const components = [
  //INSTALL-COMPONENTS
];

Swiper.use(components);

//EXPORT
